import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Sidebar = () => {
  const navigate = useNavigate();

  const [activeMenu, setActiveMenu] = useState("");

  const menuItems = [
    { path: "/dashboard", label: "Dashboard" },
    { path: "/bloglist", label: "Blogs List" },
    { path: "/caseevaluationlist", label: "Case Evaluation List" },
    { path: "/faqlist", label: "FaqList" },
    { path: "/blogcommentlist", label: "Blog Comments List" },
  ];

  const currentPath = window.location.pathname;

  useEffect(() => {
    setActiveMenu(currentPath);
  }, [currentPath]);

  const handleMenuClick = (path) => {
    navigate(path);
    setActiveMenu(path);
  };

  return (
    <div id="sidebar-wrapper">
      <ul className="sidebar-style-two">
        {menuItems.map((item) => (
          <li
            key={item.path}
            onClick={() => handleMenuClick(item.path)}
            className={activeMenu === item.path ? "active" : ""}
          >
            <a className="menu-style">{item.label}</a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
