import React, { useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import "react-summernote/dist/react-summernote.css";
import { useNavigate } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useFormik } from "formik";
import { axiosInstance } from "../axiosInstance";
// import "./css/custom-style.css";
import "../../css/custom-style.css";
import "../../css/responsive.css";
import "../../css/fonts.css";
import Sidebar from "./sidebar";
import AdminHeader from "./adminHeader";
import $ from "jquery";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
// import "react-summernote/dist/react-summernote.js";

const Blogs = () => {
  const navigate = useNavigate();
  const summernoteRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedSubImage, setSelectedSubImage] = useState("");

  const initialValues = {
    title: "",
    description: "",
    image: [],
    subImage: [],
  };
  const {
    values,
    errors,
    handleBlur,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    // validationSchema: loginSchema,
    onSubmit: (values) => {
      console.log(values, "valuessss");
      addBlog(values);
    },
  });

  const addBlog = async (values) => {
    const data = {};
    if (values?.title) {
      data["title"] = values.title;
    }
    if (values?.description) {
      data["description"] = values.description;
    }
    const formData = new FormData();

    if (values?.image?.length > 0) {
      for (let i = 0; i < values.image.length; i++) {
        formData.append("image", values.image[i]);
      }
    }
    if (values?.subImage?.length > 0) {
      for (let i = 0; i < values.subImage.length; i++) {
        formData.append("subImage", values.subImage[i]);
      }
    }

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    try {
      const res = await axiosInstance.post("admin/add-blog", formData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "multipart/form-data",
        },
      });
      resetForm();
      setSelectedImage("");
      setSelectedSubImage("");
      navigate("/bloglist")
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  const handleImage = (files, name) => {
    console.log(name, files);
    if (files?.length > 0) {
      if (name === "image") {
        setSelectedImage(URL.createObjectURL(files[0]));
        setFieldValue("image", Array.from(files));
      }
      if (name === "subImage") {
        setSelectedSubImage(URL.createObjectURL(files[0]));
        setFieldValue("subImage", Array.from(files));
      }
    }
  };

  return (
    <main className="main-page">
      <AdminHeader/>
      <div id="wrapper">
        <Sidebar/>
        
        <div className="right-side-content right-side-content-two">
          <div
            id="page-content-wrapper"
            className="dashboard-menuadd-section dashboard-admin-common collap-common-css"
          >
            <div className="container-fluid">
              <div className="web-header">
                <button
                  type="button"
                  className="collape-style"
                  data-toggle="collapse"
                  data-target="#Headertarget"
                >
                  Add Blog <i className="fa-solid fa-angle-down"></i>
                </button>
              </div>
              <div id="Headertarget" className="row collapse show  mt-4">
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-lg-8 mt-0">
                      <div className="inputfld-style-one">
                        <div className="inputfld-text-btn">
                          <div className="input-textselect w-100">
                            <label>Title</label>
                            <input
                              type="text"
                              name="title"
                              placeholder="Add heading text here"
                              value={values.title}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8 mt-2">
                      <div className="summernote-group">
                        <label>Description</label>
                        <div ref={summernoteRef}>
                          <CKEditor
                            editor={ClassicEditor}
                            data="<p>Description</p>"
                            onReady={(editor) => {
                              // You can store the "editor" and use when it is needed.
                              console.log("Editor is ready to use!", editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setFieldValue("description", data);
                              // console.log(values, "taa");
                            }}
                            onBlur={(event, editor) => {
                              console.log("Blur.", editor);
                            }}
                            onFocus={(event, editor) => {
                              console.log("Focus.", editor);
                            }}
                          />
                        </div>
                      </div>
                    </div>


                    <div className="col-lg-8 mt-2">
                      <div className="inputfld-style-one">
                        <div className="inputfld-text-btn">
                          <div className="input-textselect w-100">
                            <label>Meta Title</label>
                            <input
                              type="text"
                              name="metaTitle"
                              placeholder="Add heading text here"
                              value={values.metaTitle}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8 mt-2">
                      <div className="inputfld-style-one">
                        <div className="inputfld-text-btn">
                          <div className="input-textselect w-100">
                            <label>Meta Description</label>
                            <input
                              type="text"
                              name="metaDescription"
                              placeholder="Add heading text here"
                              value={values.metaDescription}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="col-lg-8 mt-4">
                      <div className="browseimg-style-two">
                        <label>Add Blog Card image</label>
                        <div className="browseimg-text-btn">
                          <div className="file-upload-sect mx-0">
                            <div className="file-drop-area">
                              <span className="fake-btn">Choose File</span>
                              <span className="file-msg"></span>
                              <input
                                className="file-input"
                                type="file"
                                name="image"
                                id="upload-img"
                                onChange={(e) =>
                                  handleImage(e.target.files, e.target.name)
                                }
                              />
                            </div>
                          </div>
                        </div>
                        {selectedImage && (
                          <div
                            className="img-thumbs img-thumbs-hidden"
                            id="img-preview"
                          >
                            <img src={selectedImage} />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <div className="browseimg-style-two">
                        <label>Add Single Blog image</label>
                        <div className="browseimg-text-btn">
                          <div className="file-upload-sect mx-0">
                            <div className="file-drop-area">
                              <span className="fake-btn">Choose File</span>
                              <span className="file-msg"></span>
                              <input
                                className="file-input"
                                type="file"
                                name="subImage"
                                id="upload-img"
                                onChange={(e) =>
                                  handleImage(e.target.files, e.target.name)
                                }
                              />
                            </div>
                          </div>
                        </div>
                        {selectedSubImage && (
                          <div
                            className="img-thumbs img-thumbs-hidden"
                            id="img-preview"
                          >
                            <img src={selectedSubImage} />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-8 mt-3 text-right w-100">
                      <button
                        type="btn"
                        className="btn btn-style-two"
                        onClick={handleSubmit}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Blogs;
