import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../axiosInstance.js";
import { ToastContainer, toast } from "react-toastify";
import { useFormik } from "formik";
// import { loginSchema } from "./components/Schemas/index.js";

const Login = () => {
  const navigate = useNavigate();
  const [passwordToggle, setPasswordToggle] = useState(false);
  const showhide = () => {
    setPasswordToggle(!passwordToggle);
  };

  const initialValues = {
    email: "",
    password: "",
  };
  const {
    values,
    errors,
    handleBlur,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    // validationSchema: loginSchema,
    onSubmit: (values) => {
      console.log(values, "valuessss");
      onFinish(values);
    },
  });

  const onFinish = async (values) => {
    try {
      const res = await axiosInstance.post("admin/login", values, {});
      console.log(res, "response");
      localStorage.setItem("token", res.data.token);
      // localStorage.setItem("authAdmin", JSON.stringify(res.data.response));
      navigate("/bloglist");
    } catch (error) {
      // toast.error({ message: error.response.data.error });
    }
  };

  return (
    <section className="login-section">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6 px-0">
            <div className="loginimg">
              <img
                src="images/login/login-img.jpg"
                className="w-100"
                alt="Login Image"
              />
              <div className="log-in-copyright">
                <p>Copyright © 2023 The Accidents Attorney</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="loginform">
              <h1>Sign In</h1>
              {/* <div className="create-act">
                <p>New user?</p>
                <a href="#">Create an account</a>
              </div> */}
              <form className="login-form" onSubmit={handleSubmit}>
                <div className="form-group mb-4">
                  <input
                    type="email"
                    name="email"
                    placeholder="Username or email"
                    className="form-control"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.email && touched.email ? (
                    <span className="errormessage">{errors.email}</span>
                  ) : null}
                </div>
                <div className="form-group mb-4">
                  <div className="input-group" id="show_hide_password">
                    <input
                      className="form-control"
                      type={passwordToggle ? "text" : "password"}
                      name="password"
                      placeholder="Password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    <div
                      class="input-group-addon "
                      style={{ cursor: "pointer" }}
                    >
                      <a onClick={() => showhide()}>
                        <i
                          class={
                            passwordToggle ? "fa fa-eye" : "fa fa-eye-slash"
                          }
                          aria-hidden="true"
                        ></i>
                      </a>
                    </div>
                  </div>
                  {errors.password && touched.password ? (
                    <span className="errormessage">{errors.password}</span>
                  ) : null}
                </div>
                <div className="form-group mb-4">
                  <div className="checkbox-style">
                    <input type="checkbox" id="signerdin" />
                    <label htmlFor="signerdin">Keep me signed in</label>
                  </div>
                </div>
                <div className="form-group">
                  <button className="btn login-btn">Login</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
