import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import CaseEvalution from "./caseEvalution";
import Style from "./WebStyle";
import Header from "./header";
import Footer from "./footer";
import config from "../../config";
import { useFormik } from "formik";
import { axiosInstance } from "../axiosInstance";
import News from "./news";
import WOW from "wowjs";
import "animate.css/animate.css";
import { Helmet } from "react-helmet";
import ReactHtmlParser from "html-react-parser";

const BlogDetail = () => {
  const navigate = useNavigate();
  const wow = new WOW.WOW();
  const [BlogDetail, setBlogDetail] = useState([]);
  const [PrevBlog, setPrevBlog] = useState(null);
  const [NextBlog, setNextBlog] = useState(null);
  const { title, id } = useParams();
  console.log(title, "titleeeee");
  useEffect(() => {
    window.scrollTo(0, 0);
    wow.init();
    getBlog(title);
  }, [title]);

  const initialValues = {
    comment: "",
    name: "",
    email: "",
  };
  const {
    values,
    errors,
    handleBlur,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    // validationSchema: loginSchema,
    onSubmit: (values) => {
      console.log(values, "valuessss");
      addComment(values);
    },
  });

  const addComment = async (values) => {
    // return console.log(BlogDetail._id, "blogssss")
    const data = {};
    data["blog_id"] = BlogDetail._id;
    if (values?.comment) {
      data["comment"] = values.comment;
    }
    if (values?.name) {
      data["name"] = values.name;
    }
    if (values?.email) {
      data["email"] = values.email;
    }

    try {
      const res = await axiosInstance.post("add-comment", data);
      resetForm();
      // navigate("/blogs");
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  // ---------------------------------get brand by id -----------------------------------
  const getBlog = async (title) => {
    try {
      const res = await axiosInstance.get(`admin/get-blog/${title}`);
      console.log(res.data.response, "title");
      setBlogDetail(res.data.response);
      getPrevNextBlog(res.data.response._id);
    } catch (error) {
      console.log(error);
    }
  };
  // ---------------------------------get brand by id -----------------------------------
  const getPrevNextBlog = async (id) => {
    // let id = BlogDetail._id
    try {
      const res = await axiosInstance.get(`get-prev-next-blog?id=${id}`);
      console.log(res.data.response, "title1234");
      setPrevBlog(res.data.response.previous);
      setNextBlog(res.data.response.next);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Style />
      {/* <!-- Header starts --> */}

      <Header />
      {/* <!-- Header ends --> */}
      <Helmet>
        <title>The Accidental Attorney</title>
        <meta name="keywords" content="Accidents/law/immigration" />
        <meta
          name="description"
          content="Get all Details about Accident Attorney"
        />
      </Helmet>

      {/* <!-- banner section starts --> */}
      <section className="banner-section banner-section-blog mt-0">
        <div className="overlay"></div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 d-flex align-items-center justify-content-center">
              <div className="bakery-heads text-center">
                <h2 className="mb-4 text-uppercase">{BlogDetail?.title}</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- banner section ends --> */}

      {/* <!-- blog section starts --> */}
      <section className="bakery-blogdetails-section blog_area">
        <div className="container">
          <div className="sale_area">
            <div className="row">
              <div className="col-lg-9 col-md-12 col-12 mb-5 mx-auto">
                <div className="sale_area_l single-post-area">
                  <div className="blog_box">
                    <div className="row">
                      <div className="col-lg-12">
                        <div
                          className="blog_pic wow fadeInUp"
                          data-wow-duration="1s"
                        >
                          <img
                            src={
                              BlogDetail?.image?.length
                                ? `${config?.imgUrl}${BlogDetail?.image[0]}`
                                : ""
                            }
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="single-post">
                          <h2
                            className="text-left mb-0 wow fadeInUp"
                            data-wow-duration="1s"
                          >
                            {BlogDetail?.title}
                          </h2>
                          <ul className="blog-info-link mt-3 mb-4">
                            {/* <li><a ><i className="fa fa-user"></i> Travel, Lifestyle</a></li> */}
                            <li>
                              <a>
                                <i className="fa fa-comments"></i> 03 Comments
                              </a>
                            </li>
                          </ul>

                          {/* <h3>Lorem Ipsum Dolor Sit Amet</h3> */}

                          <p
                            className="paragraph01 text-left wow fadeInUp"
                            data-wow-duration="1s"
                          >
                            {BlogDetail?.description &&
                              ReactHtmlParser(BlogDetail?.description)}
                          </p>
                        </div>
                        <div className="navigation-top">
                          <div className="d-sm-flex justify-content-between text-center">
                            {/* <p className="like-info"><span className="align-middle"><i className="fa fa-heart"></i></span> Lily and 4
                                          people like this
                                       </p> */}
                            <div className="col-sm-4 text-center my-2 my-sm-0"></div>
                            <ul className="social-icons">
                              <li>
                                <a
                                  href="https://www.facebook.com"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <i classNameName="fab fa-facebook-f"></i>
                                </a>
                              </li>
                              <a href="https://twitter.com" target="_blank">
                                <i classNameName="fab fa-twitter"></i>
                              </a>
                              <li>
                                <a>
                                  <i className="fas fa-globe"></i>
                                </a>
                              </li>
                              {/* <li><a ><i className="fab fa-behance"></i></a></li> */}
                            </ul>
                          </div>

                          <div className="navigation-area">
                            <div className="row">
                              {PrevBlog && (
                                <div
                                  className="col-lg-6 col-md-6 col-12 nav-left flex-row d-flex justify-content-start align-items-center"
                                  onClick={() => {
                                    navigate(`/${PrevBlog?.slug}`);
                                  }}
                                >
                                  <div className="thumb">
                                    <a>
                                      <img
                                        className="img-fluid"
                                        src={`${config?.imgUrl}/${PrevBlog?.image}`}
                                        alt=""
                                      />
                                    </a>
                                  </div>
                                  <div className="arrow">
                                    <a>
                                      <span className="fa fa-arrow-left lnr"></span>
                                    </a>
                                  </div>
                                  <div className="detials">
                                    <p>Prev Post</p>
                                    <a>
                                      <h4>{PrevBlog?.title}</h4>
                                    </a>
                                  </div>
                                </div>
                              )}
                              {NextBlog && (
                                <div
                                  className="col-lg-6 col-md-6 col-12 nav-right flex-row d-flex justify-content-end align-items-center"
                                  onClick={() => {
                                    navigate(`/${NextBlog?.slug}`);
                                  }}
                                >
                                  <div className="detials">
                                    <p>Next Post</p>
                                    <a>
                                      <h4>{NextBlog?.title}</h4>
                                    </a>
                                  </div>
                                  <div className="arrow">
                                    <a>
                                      <span className="fa fa-arrow-right lnr"></span>
                                    </a>
                                  </div>
                                  <div className="thumb">
                                    <a>
                                      <img
                                        className="img-fluid"
                                        src={`${config?.imgUrl}/${NextBlog?.image}`}
                                        alt=""
                                      />
                                    </a>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* <div className="comment comments-area">
                                    <div>
                                       <div className="content">
                                          <div className="avatar"><img src="CSSweb/images/comment/comment_1.png"/></div>
                                          <div className="content-comment">
                                             <div className="user">
                                                <h5>Cameron Williamson</h5>
                                                <span className="is-mute">17 SEP 2020</span>
                                             </div>
                                             <p>Serious astronomy fanatic like a lot of us are, you can probably remember that one event in childhood that started you along this exciting hobby.</p>
                                             <div className="content-footer">
                                                <button className="btn btn-outline"><i className="fa-solid fa-share"></i>Reply</button>
                                                <button className="btn"><i className="fa-regular fa-ellipsis"></i></button>
                                             </div>
                                          </div>
                                          <div className="rate">
                                             <button className="btn"><i className="fa fa-angle-down"></i></button>
                                             <div className="value green">7</div>
                                             <button className="btn"><i className="fa fa-angle-up"></i></button>
                                          </div>
                                       </div>
                                       <div className="footer"></div>
                                    </div>
                                    <div className="subcomment">
                                       <div className="icon">
                                       </div>
                                       <div className="other_comments">
                                          <div className="com">
                                             <div className="content">
                                                <div className="avatar"><img src="CSSweb/images/comment/comment_2.png"/></div>
                                                <div className="content-comment">
                                                   <div className="user">
                                                      <h5>Bessie Cooper</h5>
                                                      <span className="is-mute">17 SEP 2020</span>
                                                   </div>
                                                   <p>Serious astronomy fanatic like.</p>
                                                   <div className="content-footer">
                                                      <button className="btn btn-outline"><i className="fa-solid fa-share"></i>Reply</button>
                                                      <button className="btn"><i className="fa-regular fa-ellipsis"></i></button>
                                                   </div>
                                                </div>
                                                <div className="rate">
                                                   <button className="btn"><i className="fa fa-angle-down"></i></button>
                                                   <div className="value red">-49</div>
                                                   <button className="btn"><i className="fa fa-angle-up"></i></button>
                                                </div>
                                             </div>
                                             <div className="footer"></div>
                                          </div>
                                          <div className="com">
                                             <div className="content">
                                                <div className="avatar"><img src="CSSweb/images/comment/comment_3.png"/></div>
                                                <div className="content-comment">
                                                   <div className="user">
                                                      <h5>Kathryn Murphy</h5>
                                                      <span className="is-mute">17 SEP 2020</span>
                                                   </div>
                                                   <p>Serious astronomy fanatic like.</p>
                                                   <div className="content-footer">
                                                      <button className="btn btn-outline"><i className="fa-solid fa-share"></i>Reply</button>
                                                      <button className="btn"><i className="fa-regular fa-ellipsis"></i></button>
                                                   </div>
                                                </div>
                                                <div className="rate">
                                                   <button className="btn"><i className="fa fa-angle-down"></i></button>
                                                   <div className="value green">7</div>
                                                   <button className="btn"><i className="fa fa-angle-up"></i></button>
                                                </div>
                                             </div>
                                             <div className="footer"></div>
                                          </div>
                                       </div>
                                    </div>
                                 </div> */}
                        <div className="comment-form">
                          <h4>Leave a Reply</h4>
                          <form
                            className="form-contact comment_form"
                            action="#"
                            id="commentForm"
                          >
                            <div className="row">
                              <div className="col-12">
                                <div className="form-group">
                                  <textarea
                                    className="form-control w-100"
                                    name="comment"
                                    id="comment"
                                    cols="30"
                                    rows="9"
                                    placeholder="Write Comment"
                                    value={values.comment}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  ></textarea>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <input
                                    className="form-control"
                                    name="name"
                                    id="name"
                                    type="text"
                                    placeholder="Name"
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <input
                                    className="form-control"
                                    name="email"
                                    id="email"
                                    type="email"
                                    placeholder="Email"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </div>
                              </div>
                              {/* <div className="col-12">
                                             <div className="form-group">
                                                <input className="form-control" name="website" id="website" type="text" placeholder="Website"/>
                                             </div>
                                          </div> */}
                            </div>
                            <div className="form-group">
                              <button
                                type="submit"
                                className="btn-7 btn-style-one mt-3 w-100"
                                onClick={handleSubmit}
                              >
                                <span>Send Message</span>
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- // --> */}
                </div>
              </div>
              {/* <!-- // -->                     */}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- blog section ends --> */}

      {/* <!-- News section section Starts --> */}
      <section className="news_sections bg-light">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="heading-style-one mb-4 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <h2>Recent News</h2>
                <div className="animation_heading_style mb-5"></div>
              </div>
            </div>
            <News />
          </div>
        </div>
      </section>
      {/* <!-- News section section end --> */}

      <CaseEvalution />

      {/* <!-- footer section start --> */}
      <Footer />
      {/* <!-- footer section end --> */}
    </>
  );
};

export default BlogDetail;
