import React, { useEffect, useState,useRef } from "react";
function WebStyle() {
     return (
         <div id="root">            
            <link href="../CSSweb/css/allmin.css" rel="stylesheet" crossorigin="anonymous"></link> 
            <link href="../CSSweb/css/responsive.css" rel="stylesheet" crossorigin="anonymous"></link> 
            <link href="../CSSweb/css/style.css" rel="stylesheet" crossorigin="anonymous"></link> 
            <link href="../CSSweb/css/clocklet.min.css" rel="stylesheet" crossorigin="anonymous"></link> 
            <link href="../CSSweb/css/fancybox.css" rel="stylesheet" crossorigin="anonymous"></link> 
            <link href="../CSSweb/css/owl-carousal.css" rel="stylesheet" crossorigin="anonymous"></link> 
            <link href="../CSSweb/css/owl-theme.css" rel="stylesheet" crossorigin="anonymous"></link> 
            <link href="../CSSweb/css/jquery-ui.css" rel="stylesheet" crossorigin="anonymous"></link> 
            <link href="../CSSweb/css/select2.min.css" rel="stylesheet" crossorigin="anonymous"></link> 
            {/* <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css" integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3" crossorigin="anonymous" /> */}
    </div>
  );
}
export default WebStyle;