import React, { useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

function TruncatedParagraph({ content }) {
  const [showFullContent, setShowFullContent] = useState(false);

  const handleClick = () => {
    setShowFullContent(!showFullContent);
  };

  const truncateContent = (content, charLimit) => {
    if (content.length > charLimit) {
      return (
        <p
          dangerouslySetInnerHTML={{
            __html: content.slice(0, charLimit) + "...",
          }}
        ></p>
      );
    }
    return (
      <p
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      ></p>
    );
  };

  const renderTooltip = (props) => (
    <Tooltip id="fullContentTooltip" {...props}>
      <p
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      ></p>
    </Tooltip>
  );

  return (
    <>
      <OverlayTrigger placement="left" overlay={renderTooltip}>
        <p onClick={handleClick}>{truncateContent(content, 25)}</p>
      </OverlayTrigger>
    </>
  );
}

export default TruncatedParagraph;
