// eslint-disable
import React, { useEffect, useState } from "react";
import { axiosInstance } from "../axiosInstance";
// import deleteIcon from "./assets/images/icons/delete-icon.png";
import deleteIcon from "../../assets/images/icons/delete-icon.png";
import { useNavigate } from "react-router-dom";
import editIcon from "../../assets/images/icons/edit-icon.png";
import config from "../../config";
import Sidebar from "./sidebar";
import AdminHeader from "./adminHeader";
import TruncatedParagraph from "../Truncate";

const BlogList = () => {
  const navigate = useNavigate();
  const [Data, setData] = useState([]);
  const [showFullContent, setShowFullContent] = useState(false);

  const listBlogs = async () => {
    const handleClick = () => {
      setShowFullContent(!showFullContent);
    };

    try {
      const res = await axiosInstance.get("admin/all-blogs");
      console.log(res.data.response, "bloglist");
      setData(res.data.response);
    } catch (error) {
      // toast(error.response.data.response.message);
    }
  };
  useEffect(() => {
    listBlogs();
  }, []);

  const DeleteBlog = async (id) => {
    try {
      const res = await axiosInstance.delete(`admin/delete-blog/${id}`);
      listBlogs();
    } catch (error) {}
  };

  const changeStatus = async (e, id) => {
    try {
      const res = await axiosInstance.patch(`admin/edit-blog/${id}`, {
        status: e.target.checked,
      });
      alert("Status changed successfully");
      //   toast(response.data.data.message);
      listBlogs();
    } catch (error) {
      //   toast(error.response.data.data.message);
    }
  };

  return (
    <main className="main-page">
      <AdminHeader />

      <div id="wrapper">
        <Sidebar />
        <div className="right-side-content">
          <div id="page-content-wrapper" className="menu-datatable-section">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-5 web-header">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Blog List
                      </li>
                    </ol>
                  </nav>
                </div>
                <div className="col-lg-12 table-sectn">
                  <table
                    id=""
                    className="table table-style-two"
                    cellSpacing="0"
                    width="100%"
                  >
                    <thead>
                      <tr>
                        <th>Image</th>
                        <th>Title</th>
                        <th>Description</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Data &&
                        Data.map((item, i) => {
                          return (
                            <tr>
                              <td>
                                <div className="menu-item-img">
                                  <img
                                    src={`${config.imgUrl}/${item.image[0]}`}
                                    alt="Blog"
                                    height="80"
                                  />
                                </div>
                              </td>
                              <td>{item.title}</td>
                              <td>
                                {/* <p
                                  dangerouslySetInnerHTML={{
                                    __html: item?.description,
                                  }}
                                ></p> */}
                                {item?.description?.length > 25 ? (
                                  <TruncatedParagraph
                                    content={item?.description}
                                  />
                                ) : (
                                  <p
                                  dangerouslySetInnerHTML={{
                                    __html: item?.description,
                                  }}
                                ></p>
                                )}
                                <br />

                                {/* pickles, aioli and BBQ sauce with side chips. */}
                              </td>
                              <td>
                                <div className="toggles">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={item?.status}
                                      onChange={(e) =>
                                        changeStatus(e, item?._id)
                                      }
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </div>
                              </td>
                              <td>
                                <ul className="menu-table-action">
                                  <li onClick={() => DeleteBlog(item._id)}>
                                    <a>
                                      <img
                                        src={deleteIcon}
                                        alt=""
                                        height="18"
                                      />
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      onClick={() =>
                                        navigate("/editblog", {
                                          state: {
                                            id: item.slug,
                                            id1: item._id,
                                          },
                                        })
                                      }
                                    >
                                      <img src={editIcon} alt="" height="18" />
                                    </a>
                                  </li>
                                </ul>
                              </td>
                            </tr>
                          );
                        })}
                      {/* Repeat the above <tr> for other blog items */}
                    </tbody>
                  </table>
                </div>
                <div className="col-lg-8 mt-3 text-right w-100">
                  <button
                    type="btn"
                    className="btn btn-style-two"
                    onClick={() => navigate("/addblogs")}
                  >
                    Add Blog
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default BlogList;
