import React, { useEffect } from 'react'
import Style from "./WebStyle";
import Header from './header'
import Footer from './footer';
import heroImage from '../../assets/images/hero_bg_1.jpg';
import bgImage from '../../assets/images/bg-map.png';
import WOW from 'wowjs';
import 'animate.css/animate.css';

const AboutUs = () => {
    const wow = new WOW.WOW();
    

    useEffect(()=>{
        wow.init();
    },[])
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

  return (
    <>
    <Style/>
     {/* <!-- Header starts --> */}
     <Header/>
    {/* <!-- Header ends --> */}

    {/* <!-- banner section starts --> */}
    <section class="banner-section banner-section-about">
        <div class="overlay"></div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12 d-flex align-items-center justify-content-center">
                    <div class="bakery-heads text-center wow bounceIn" data-wow-delay="0.1s">
                        <h2 class="mb-4 text-uppercase">About Us</h2>
                    </div>
                </div>
            </div>
        </div>
    </section>

    {/* <section class="banner-section-blog mt-0">
        <div class="overlay"></div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 d-flex align-items-center justify-content-center">
              <div class="bakery-heads text-center wow bounceIn" data-wow-delay="0.1s">
                <h2 class="mb-4 text-uppercase">About Us</h2>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    {/* <!-- banner section ends --> */}

    {/* <!-- about us section section start --> */}
    <section class="about_us_section">
        <div class="container">
            <div class="row align-items-center">            	
                <div class="col-lg-4 mb-4 wow bounceInLeft" data-wow-delay="0.1s">
                    <img src="CSSweb/images/about_2.png" class="img-fluid" alt=""/>                    
                </div>
            	<div class="col-lg-4">
            		<div class="text-center mb-5">
                         <div class="heading-style-one mb-4 wow fadeInUp" data-wow-delay="0.1s">
                           <h2>About Our Firm</h2>
                           <div class="animation_heading_style mb-5"></div>
                       </div> 
            			 <p class=" wow bounceIn" data-wow-delay="0.1s">We are one of the largest The Accidents Attorney in the region, composed entirely of seasoned lawyers who love their work. We know what it takes to build and present a solid and compelling case, and that’s exactly what we do. Vigorously and earnestly, we tackle the most complex challenge and pursue your goals with creativity and determination.</p>
            			 <p class=" wow bounceIn" data-wow-delay="0.1s">With office in Fresno, California, we offer sophisticated legal representation to businesses and individuals from coast to coast. Our solicitors are the best in their fields, providing optimal solutions to our clients. </p>            			 
            		</div>
            	</div>
                <div class="col-lg-4 mb-4 wow bounceInRight" data-wow-delay="0.1s">
                    <img src="CSSweb/images/about_2.png" class="img-fluid" alt=""/>                    
                </div>
               
            </div>
        </div>
    </section>
    {/* <!-- about us section section end --> */}

    {/* <!-- Our Mission section Starts --> */}
    <section class="our_mission_section bg_style_one"  style={{ backgroundImage: `url(${heroImage})` }}>
    	<div class="container">
    		<div class="row">
    			<div class="col-md-9 mx-auto">
    				<div class="text-center mb-5">
                        <div class="heading-style-one mb-4 wow fadeInUp" data-wow-delay="0.1s">
                           <h2>Our Mission</h2>
                           <div class="animation_heading_style mb-5"></div>
                       </div>            			
            			 <p class=" wow fadeInUp" data-wow-delay="0.1s">Our mission is to provide each and every client with skilled legal advice in a timely and efficient manner. We handle every case with accountability and responsiveness. Each of our lawyers is dedicated to focusing on the legal aspects of your case, so that you can focus on your personal success. Our mission is based on the values we hold true: integrity, service and excellence.</p>
            		</div>
    			</div>
    		</div>
    	</div>
    </section>
{/* <!-- Our Mission section end --> */}

{/* <!-- Our Work Locally section Starts --> */}
 <section class="our_Work_section">
    	<div class="container">
    		<div class="row align-items-center">
    			 <div class="col-lg-4 mb-4 wow bounceInLeft" data-wow-delay="0.1s">
                    <img src="CSSweb/images/about_2.png" class="img-fluid" alt=""/>                    
                </div>
                <div class="col-lg-8">
            		<div class="text mb-5">
                        <div class="heading-style-two mb-4 wow fadeInUp" data-wow-delay="0.1s">
                           <h2>We Work Locally</h2>
                           <div class="animation_heading_style mb-5"></div>
                       </div>             			
            			 <p class=" mb-4 wow fadeInUp" data-wow-delay="0.1s">As an attorney who works locally with reputable firms such as Steven Law Group and Jagdeep Singh Insurance, a respected truck insurance provider, I have discovered immense rewards and advantages in this professional arrangement. The ability to collaborate with multiple The Accidents Attorneys has significantly contributed to the expansion of my legal knowledge and expertise across various areas of the law. With each firm, I am immersed in diverse types of cases and legal matters, continuously honing my skills and developing into a versatile legal professional.</p>
            			 <p class=" mb-4 wow fadeInUp" data-wow-delay="0.2s">One of the most significant advantages of working locally with various firms is the expansive network it provides. Through my collaborations, I have had the privilege of establishing connections with a wide range of lawyers, judges, and professionals within the local legal community. These connections have proven invaluable in terms of resources, mentorship, and referral opportunities. Additionally, the network has fostered a sense of community and camaraderie, allowing me to contribute to and learn from the vibrant legal ecosystem in my locality.</p>            			 
            		</div>
            	</div>
    		</div>
    	</div>
    </section>
{/* <!-- Our Work Locally section end --> */}

{/* <!-- Personal Injury section Starts --> */}
    <section class="personal_injry_section">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="heading-style-one mb-4 wow fadeInUp" data-wow-delay="0.1s">
                       <h2>Personal Injury Lawyer Fresno – Award Winning Lawyers</h2>
                       <div class="animation_heading_style mb-5"></div>
                   </div>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-2">
                    <div class="logo_bg_injry wow bounceInUp" data-wow-delay="0.1s">
                        <img src="CSSweb/images/logo/logo_1.png"/>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="logo_bg_injry wow bounceInUp" data-wow-delay="0.2s">
                        <img src="CSSweb/images/logo/logo_2.png"/>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="logo_bg_injry wow bounceInUp" data-wow-delay="0.3s">
                        <img src="CSSweb/images/logo/logo_3.png"/>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="logo_bg_injry wow bounceInUp" data-wow-delay="0.4s">
                        <img src="CSSweb/images/logo/logo_4.png"/>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="logo_bg_injry wow bounceInUp" data-wow-delay="0.5s">
                        <img src="CSSweb/images/logo/logo_5.png"/>
                    </div>
                </div>
                <div class="col-md-1"></div>
            </div>
        </div>
    </section>
    {/* <!-- Personal Injury section end --> */}

    {/* <!-- Case Evaluation section Start --> */}
    <section class="case_evaluation">
        <div class="container">
            <div class="row">
                 <div class="col-md-6  wow flipInY" data-wow-delay="0.1s">
                     <img src="CSSweb/images/Img_001.png"/>
                 </div>
                 <div class="col-md-6">
                    <div class="heading-style-two mb-4 mb-4 wow fadeInUp" data-wow-delay="0.1s">
                       <h2>Case Evaluation</h2>
                       <div class="animation_heading_style mb-5"></div>
                       <p>Sound legal advice is based on years of training, hard work, and passion, as well as familiarity with legislation and precedent and sound consideration. You’ll find all that and more at The Accidents Attorney.</p>
                    </div>
                    <form class="case_contact wow bounceInRight" data-wow-delay="0.1s">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input type="text" name="" placeholder="First Name" class="form-control"/>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input type="text" name="" placeholder="Last Name" class="form-control"/>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input type="text" name="" placeholder="Phone" class="form-control"/>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input type="text" name="" placeholder="Email" class="form-control"/>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <textarea type="text" name="" placeholder="Case Details" class="form-control"></textarea>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <button class="btn-7 btn-style-one w-100"><span>Submit</span></button>
                                </div>
                            </div>
                        </div>
                    </form>
                        
                    </div>


                 </div>
            </div>
      
    </section>
    {/* <!-- Case Evaluation section end --> */}

    {/* <!-- footer section start --> */}
    <Footer/>
    {/* <!-- footer section end --> */}
    
    
    </>
  )
}

export default AboutUs