import React, { useEffect, useState } from "react";
import { axiosInstance } from "../axiosInstance";
import { useNavigate } from "react-router-dom";
import Style from "./WebStyle";
import Header from "./header";
import Footer from "./footer";
import WOW from 'wowjs';
import config from "../../config";
import 'animate.css/animate.css';

const BlogPage = () => {
  const navigate = useNavigate();
  const wow = new WOW.WOW();
  useEffect(()=>{
      wow.init();
  },[])
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [Data, setData] = useState([]);
  const [show, setShow] = useState(true)
  const [showFullContent, setShowFullContent] = useState(false);

  const listBlogs = async () => {
    const handleClick = () => {
      setShowFullContent(!showFullContent);
    };

    try {
      const res = await axiosInstance.get("admin/all-blogs");
      console.log(res.data.response, "bloglist");
      setData(res.data.response);
    } catch (error) {
      // toast(error.response.data.response.message);
    }
  };
  useEffect(() => {
    listBlogs();
  }, []);

  return (
    <>
      <Style />
      {/* <!-- Header starts --> */}
      <Header />
      {/* <!-- Header ends --> */}

      {/* <!-- banner section starts --> */}
      <section class="banner-section banner-section-blog mt-0">
        <div class="overlay"></div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12 d-flex align-items-center justify-content-center">
                    <div class="bakery-heads text-center wow bounceIn" >
                        <h2 class="mb-4 text-uppercase">Blog</h2>
                    </div>
                </div>
            </div>
        </div>
    </section>
      {/* <!-- banner section ends --> */}

      {/* <!-- branch section start --> */}
      <section class="branch-1 mb-5">
        <div class="container">
        {Data &&
                        Data.map((item, i) => {
                          let Url = item.slug;
                          return (
          <div class="row align-items-center">
            <div class="col-lg-6 inside">
              <img
               src={`${config.imgUrl}/${item.image[0]}`}
                class="img-fluid"
                alt=""
              />
              <a href="#">07 June 2021</a>
            </div>
            <div class="col-lg-6">
              <h4>{item.title}</h4>
              <span>
                <i class="fas fa-image"></i>
                <p>News</p>
              </span>
              <div class="detail">
                <ul>
                  <li>
                    <i class="fas fa-user"></i>By Accidental Attorney
                  </li>
                </ul>
              </div>
              <p>
              <div dangerouslySetInnerHTML={{
                                  __html: item.description?.length < 35
                                    ? `${item.description}`
                                    : `${item.description?.substring(0, 250)}...`
                                }} /> 

              </p>
              <button class="btn-2" onClick={() => {navigate(`/${Url}`);
                                        }}>
                <i class="fas fa-angle-right"></i>
              </button>
            </div>
            <div class="col-12 line text-center">
              <img
                class="icon_ham"
                src="CSSweb/images/blog/blog-icon.png"
                //  class="img-fluid"
                alt=""
              />
            </div>
          </div>
          );
        })}
          {/* <div class="row align-items-center">
            <div class="col-lg-6 inside">
              <img
                src="CSSweb/images/blog/blog-img-2.png"
                class="img-fluid"
                alt=""
              />
              <a href="#">04 February 2021</a>
            </div>
            <div class="col-lg-6">
              <h4>How to Find a Lawyer for an Amazon Van Accident?</h4>
              <span>
                <i class="fas fa-image"></i>
                <p>News</p>
              </span>
              <div class="detail">
                <ul>
                  <li>
                    <i class="fas fa-user"></i>By SSD Law Firm
                  </li>
                </ul>
              </div>
              <p>Find a lawyer who specializes in Amazon Van Accidents</p>
              <button class="btn-2">
                <i class="fas fa-angle-right"></i>
              </button>
            </div>
            <div class="col-12 line text-center">
              <img
                class="icon_ham"
                src="CSSweb/images/blog/blog-icon.png"
                // class="img-fluid"
                alt=""
              />
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-lg-6 inside">
              <img
                src="CSSweb/images/blog/blog-img-3.png"
                class="img-fluid"
                alt=""
              />
              <a href="#">07 March 2021</a>
            </div>
            <div class="col-lg-6">
              <h4>Looking for a Dog Bite Injury Attorney in Fresno?</h4>
              <span>
                <i class="fas fa-image"></i>
                <p>News</p>
              </span>
              <div class="detail">
                <ul>
                  <li>
                    <i class="fas fa-user"></i>By SSD Law Firm
                  </li>
                </ul>
              </div>
              <p>Dog Injuries Can Be Life Threatening</p>
              <button class="btn-2">
                <i class="fas fa-angle-right"></i>
              </button>
            </div>
            <div class="col-12 line text-center">
              <img
                class="icon_ham"
                src="CSSweb/images/blog/blog-icon.png"
                // class="img-fluid"
                alt=""
              />
            </div>
            <div class="col-lg-12 text-center mt-5 d-flex align-items-center middle_pagination">
              <div class="pagination">
                <a href="#" class="active">
                  1
                </a>
                <a class="#" href="#">
                  2
                </a>
                <a href="#">3</a>
              </div>
              <div class="next">
                <a href="#">NEXT</a>
              </div>
            </div>
          </div> */}
        </div>
      </section>
      {/* <!-- branch section end --> */}
      <Footer/>
      {/* <!-- footer section start --> */}
    
    </>
  );
};

export default BlogPage;
