import React from 'react'

const AdminHeader = () => {
  return (
   <>
   <header className="header-admin-one">
        <div className="top-nav">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-6 col-sm-6 logo-header">
                <div className="logo-main">
                  <a
                    href="#menu-toggle"
                    className="btn btn-default menu-toggle"
                  >
                    <i className="fas fa-bars"></i>
                  </a>
                  <img src="images/logomain.svg" alt="Logo" />
                </div>
              </div>
              <div className="col-lg-6 col-sm-6 user d-flex justify-content-end">
                <div className="user-name ">
                  <p>Sarwinder</p>
                  <p className="admin-super">
                    <b>Admin</b>
                  </p>
                </div>
                <span>
                  <img src="images/user-img.png" alt="User" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </header>
   
   </>
  )
}

export default AdminHeader