import React, { useEffect } from "react";
import Iframe from "react-iframe";
import Style from "./WebStyle";
import Header from "./header";
import CaseEvalution from "./caseEvalution";
import Footer from "./footer";
import WOW from "wowjs";
import "animate.css/animate.css";

const ContactUs = () => {
  const wow = new WOW.WOW();

  useEffect(() => {
    wow.init();
    window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <Style />
      {/* <!-- Header starts --> */}
      <Header />
      {/* <!-- Header ends --> */}

      {/* <!-- banner section starts --> */}
      <section class="banner-section banner-section-FAQs mt-0">
        <div class="overlay"></div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 d-flex align-items-center justify-content-center">
              <div
                class="bakery-heads text-center wow bounceIn"
                data-wow-delay="0.1s"
              >
                <h2 class="mb-4 text-uppercase">Contact Us</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- banner section ends --> */}

      {/* <!-- map section start --> */}
      <section class="map_section">
        <div class="container-fluid">
          <div class="row">
            <div className="col-md-12 px-0">
              <Iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3196.0438219258094!2d-119.7343375242724!3d36.769516172255884!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80945c28a2e3c7c9%3A0x420af23984c7b84c!2sN%20Winery%20Ave%2C%20Fresno%2C%20CA%2093703%2C%20USA!5e0!3m2!1sen!2sin!4v1686597168331!5m2!1sen!2sin"
                width="600"
                height="450"
                frameBorder="0"
                style={{ border: "0" }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></Iframe>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- map section start --> */}

      {/* <!-- Case Evaluation section Start --> */}
      <section class="case_evaluation case-eva-contact">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-12 col-lg-8">
              <div
                class="heading-style-two mb-4 mb-4 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <h2>Case Evaluation</h2>
                <div class="animation_heading_style mb-5"></div>
                <p>
                  Sound legal advice is based on years of training, hard work,
                  and passion, as well as familiarity with legislation and
                  precedent and sound consideration. You’ll find all that and
                  more at The Accidents Attorney.
                </p>
              </div>
              <form class="case_contact wow bounceInLeft" data-wow-delay="0.1s">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <input
                        type="text"
                        name=""
                        placeholder="First Name"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <input
                        type="text"
                        name=""
                        placeholder="Last Name"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <input
                        type="text"
                        name=""
                        placeholder="Phone"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <input
                        type="text"
                        name=""
                        placeholder="Email"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <textarea
                        type="text"
                        name=""
                        placeholder="Case Details"
                        class="form-control"
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <button class="btn-7 btn-style-one w-100">
                        <span>Submit</span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div
              class="col-lg-3 offset-lg-1  wow  bounceInRight"
              data-wow-delay="0.1s"
            >
              <div class="contactbx">
                <div class="media contact-info">
                  <span class="contact-info__icon">
                    <i class="fa-solid fa-location-dot"></i>
                  </span>
                  <div class="media-body">
                    <h3>Fresno.</h3>
                    <p>2170 N. Winery Ave Fresno, CA 93703</p>
                  </div>
                </div>
                <div class="media contact-info">
                  <span class="contact-info__icon">
                    <i class="fa-solid fa-mobile"></i>
                  </span>
                  <div class="media-body">
                    <h3>(559)342-5000</h3>
                    <p>Mon to Fri 9am to 6pm</p>
                  </div>
                </div>
                <div class="media contact-info">
                  <span class="contact-info__icon">
                    <i class="fa-solid fa-envelope"></i>
                  </span>
                  <div class="media-body">
                    <h3>sdhanjan@lawfirmssd.com</h3>
                    <p>Send us your query anytime!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Case Evaluation section end --> */}

      <Footer />
    </>
  );
};

export default ContactUs;
