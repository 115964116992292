import React, { useState, useEffect } from "react";
import Style from "./WebStyle";
import { useNavigate, useLocation } from "react-router-dom";
const Header = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Get the current URL location
  const [isSticky, setIsSticky] = useState(false);
  const [home, setHome] = useState(false);
  const [practiceArea, setPracticeArea] = useState(false);
  const [aboutUs, setAboutUs] = useState(false);
  const [ourTeam, setOurTeam] = useState(false);
  const [blogs, setBlogs] = useState(false);
  const [faqs, setFaqs] = useState(false);
  const [contactUs, setContactUs] = useState(false);
  const [closeBtn, setCloseBtn] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const path = location.pathname.substr(1);
    setHome(path === "");
    setPracticeArea(path === "practiceArea");
    setAboutUs(path === "aboutus");
    setOurTeam(path === "OurTeam");
    setBlogs(path === "allblogs");
    setFaqs(path === "faq");
    setContactUs(path === "contactus");
  }, [location]);

  return (
    <>
      <Style />
      {/* <!-- Header starts --> */}
      <header id="header" className={isSticky ? "sticky" : ""}>
        <div className="top-bar">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 text-left px-0">
                <ul className="mail_top">
                  <li>
                    <a href="tel:5593425000">
                      <i className="fa-solid fa-phone"></i> (559)342-5000
                    </a>
                  </li>
                  <li>
                    <a href="mailto:sdhanjan@lawfirmssd.com">
                      <i className="fa-solid fa-envelope"></i>{" "}
                      sdhanjan@lawfirmssd.com
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-6 text-right px-0">
                <ul className="social_icons">
                  <li>
                    <a href="https://www.facebook.com" target="_blank">
                      <i className="fa-brands fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com" target="_blank">
                      <i className="fa-brands fa-linkedin-in"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Navbar starts --> */}
        <div className="container px-0">
          <nav className="navbar navbar-expand-lg navbar-light bg-transparent px-0">
            <div className="logo-mob">
              <a className="navbar-brand" href="/">
                <img src="CSSweb/images/logomain.svg" alt="logo" />
              </a>
              <button onClick={() => setCloseBtn(false)}>
                <i className="fa-solid fa-bars"></i>
              </button>
            </div>
            <div
              className={
                closeBtn
                  ? "justify-content-between sidenav"
                  : "justify-content-between sidenav sidemob"
              }
              id="navbarNav"
            >
              <a className="closebtn" onClick={() => setCloseBtn(true)}>
                &times;
              </a>
              <ul className="navbar-nav logo-desk">
                <li className="nav-item">
                  <a className="nav-link" href="/">
                    <img src="CSSweb/images/logomain.svg" alt="logo" />
                  </a>
                </li>
              </ul>
              <ul className="navbar-nav">
                <li
                  className={home ? "nav-item active" : "nav-item"}
                  onClick={() => navigate("/")}
                >
                  <a className="nav-link">Home</a>
                </li>
                <li
                  className={practiceArea ? "nav-item active" : "nav-item"}
                  onClick={() => navigate("/practiceArea")}
                >
                  <a className="nav-link">Practice Areas</a>
                </li>
                <li
                  className={aboutUs ? "nav-item active" : "nav-item"}
                  onClick={() => navigate("/aboutus")}
                >
                  <a className="nav-link">About Us</a>
                </li>
                <li
                  className={ourTeam ? "nav-item active" : "nav-item"}
                  onClick={() => navigate("/OurTeam")}
                >
                  <a className="nav-link">Our Team</a>
                </li>
                <li
                  className={blogs ? "nav-item active" : "nav-item"}
                  onClick={() => navigate("/allblogs")}
                >
                  <a className="nav-link">Blogs</a>
                </li>
                <li
                  className={faqs ? "nav-item active" : "nav-item"}
                  onClick={() => navigate("/faq")}
                >
                  <a className="nav-link">FAQs</a>
                </li>
                <li
                  className={contactUs ? "nav-item active" : "nav-item"}
                  onClick={() => navigate("/contactus")}
                >
                  <a className="nav-link">Contact Us</a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        {/* <!-- Navbar ends --> */}
      </header>
      {/* <!-- Header ends --> */}
    </>
  );
};

export default Header;
