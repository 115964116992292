import React, { useEffect } from 'react'
import Header from "./header";
import Footer from "./footer";
import CaseEvalution from './caseEvalution';
import WOW from 'wowjs';
import 'animate.css/animate.css';
import { useNavigate, useLocation } from "react-router-dom";

const PracticeArea = () => {
    const navigate = useNavigate();
    const wow = new WOW.WOW();
    

    useEffect(()=>{
        wow.init();
        window.scrollTo(0, 0)
    },[])

  return (
   <>
   <Header/>

   {/* <!-- banner section starts --> */}
    {/* <section class="banner-section banner-section-practice">
        <div class="overlay"></div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12 d-flex align-items-center justify-content-center">
                    <div class="bakery-heads text-center wow bounceIn" data-wow-delay="0.1s">
                        <h2 class="mb-4 text-uppercase">Practice Areas</h2>
                    </div>
                </div>
            </div>
        </div>
    </section> */}

<section class="banner-section banner-section-practice">
        <div class="overlay"></div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12 d-flex align-items-center justify-content-center">
                    <div class="bakery-heads text-center wow bounceIn" data-wow-delay="0.1s">
                        <h2 class="mb-4 text-uppercase">Practice Areas</h2>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- banner section ends --> */}


    {/* <!-- Practice Area section section Starts --> */}
     <section class="how_can_help">
            <div class="container">
                <div class="row">
                    <div class="col-lg-7 mx-auto">                    
                       <div class="heading-style-one text-center mb-4 wow fadeInUp" data-wow-delay="0.1s">
                           <h2>Practice Areas</h2>
                           <div class="animation_heading_style mb-5"></div>
                           <p>In each area of service that we offer, we promise that you will receive the very best legal treatment in the industry. If the area of expertise you require isn’t covered below, let us know via the contact form below and we’ll do our best to refer you onwards. </p>
                       </div>
                    </div>
                    <div class="col-lg-6 mt-4"  onClick={() => navigate("/PracticeDetail")}>
                        <div class="help_bx wow bounceInUp" data-wow-delay="0.2s">
                            <div class="img_bx_help">
                                <img src="CSSweb/images/practice-area/img_001.png"/>
                            </div>
                            <div class="hrlp_cnttn">
                                <h4><a>Personal Injury <i class="fa-solid fa-arrow-right"></i></a></h4>
                                <p>We assist our clients in every aspect of their personal injury matters by formulating a strategy.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 mt-4" onClick={() => navigate("/PracticeDetail")}>
                        <div class="help_bx wow bounceInUp" data-wow-delay="0.3s">
                            <div class="img_bx_help">
                                <img src="CSSweb/images/practice-area/img_002.png"/>
                            </div>
                            <div class="hrlp_cnttn">
                                <h4><a href="#">Immigration <i class="fa-solid fa-arrow-right"></i></a></h4>
                                <p>We provide legal services for all areas of immigration law.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    {/* <!-- Practice Area section section end --> */}

<CaseEvalution/>

   <Footer/>
   </>
  )
}

export default PracticeArea