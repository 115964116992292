import React, { useEffect } from 'react'
import Style from "./WebStyle";
import Header from './header'
import Footer from './footer';
import WOW from 'wowjs';
import 'animate.css/animate.css';
import CaseEvalution from './caseEvalution';

const PracticeDetail = () => {
    const wow = new WOW.WOW();
    

    useEffect(()=>{
        wow.init();
    },[])
  return (
   <>
    <Style/>
     {/* <!-- Header starts --> */}
     <Header/>
    {/* <!-- Header ends --> */}

    {/* <!-- banner section starts --> */}
      <section class="banner-section banner-section-practicearea mt-0">
         <div class="overlay"></div>
         <div class="container-fluid">
            <div class="row">
               <div class="col-md-12 d-flex align-items-center justify-content-center">
                  <div class="bakery-heads text-center">
                     <h2 class="mb-4 text-uppercase">Practice Area Details</h2>
                  </div>
               </div>
            </div>
         </div>
      </section>
      {/* <!-- banner section ends --> */}

      {/* <!-- blog section starts --> */}
      <section class="practice-details-section practice_area">
         <div class="container">
            <div class="row">
              <div class="col-lg-9 col-md-12 col-12 mx-auto">
                 <div class="blog_pic wow bounceIn" data-wow-duration="1s"> 
                    <img src="CSSweb/images/practice-area/img_001.png" class="img-fluid" alt="" /> 
                 </div>
                 <div class="single-post">
                    <h2 class="text-left wow fadeInUp" data-wow-duration="1s">4 Ways You Can Hire the Right Personal Injury Lawyer</h2>
                    <p class="paragraph01 text-left wow fadeInUp" data-wow-duration="1s">Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Nullam Blandit Hendrerit Faucibus. Suspendisse Hendrerit Turpis Dui, Eget Ultricies Erat Consequat Ut. Sed Ac Velit Iaculis, Condimentum Neque Eu, Maximus Urna. Maecenas Vitae Nunc Sit Amet Risus Aliquet Facilisis. Cum Sociis Natoque Penatibus Et Magnis Dis Parturient Montes. </p>
                    <h3>Lorem Ipsum Dolor Sit Amet</h3>
                    <p class="paragraph01 text-left wow fadeInUp" data-wow-duration="1s">Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Nullam Blandit Hendrerit Faucibus. Suspendisse Hendrerit Turpis Dui, Eget Ultricies Erat Consequat Ut. Sed Ac Velit Iaculis, Condimentum Neque Eu, Maximus Urna. Maecenas Vitae Nunc Sit Amet Risus Aliquet Facilisis. Cum Sociis Natoque Penatibus Et Magnis Dis Parturient Montes.</p>
                    <p class="paragraph01 text-left wow fadeInUp" data-wow-duration="1s">Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Nullam Blandit Hendrerit Faucibus. Suspendisse Hendrerit Turpis Dui, Eget Ultricies Erat Consequat Ut. Sed Ac Velit Iaculis, Condimentum Neque Eu, Maximus Urna. Maecenas Vitae Nunc Sit Amet Risus Aliquet Facilisis. Cum Sociis Natoque Penatibus Et Magnis Dis Parturient Montes.</p>
                    <p class="paragraph01 text-left wow fadeInUp" data-wow-duration="1s">Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Nullam Blandit Hendrerit Faucibus. Suspendisse Hendrerit Turpis Dui, Eget Ultricies Erat Consequat Ut. Sed Ac Velit Iaculis, Condimentum Neque Eu, Maximus Urna. Maecenas Vitae Nunc Sit Amet Risus Aliquet Facilisis. Cum Sociis Natoque Penatibus Et Magnis Dis Parturient Montes.</p>
                    <p class="paragraph01 text-left wow fadeInUp" data-wow-duration="1s">Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Nullam Blandit Hendrerit Faucibus. Suspendisse Hendrerit Turpis Dui, Eget Ultricies Erat Consequat Ut. Sed Ac Velit Iaculis, Condimentum Neque Eu, Maximus Urna. Maecenas Vitae Nunc Sit Amet Risus Aliquet Facilisis. Cum Sociis Natoque Penatibus Et Magnis Dis Parturient Montes.</p>
                   
                   <h3>Lorem Ipsum Dolor Sit Amet</h3>
                    <p class="paragraph01 text-left wow fadeInUp" data-wow-duration="1s">Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Nullam Blandit Hendrerit Faucibus. Suspendisse Hendrerit Turpis Dui, Eget Ultricies Erat Consequat Ut. Sed Ac Velit Iaculis, Condimentum Neque Eu, Maximus Urna. Maecenas Vitae Nunc Sit Amet Risus Aliquet Facilisis. Cum Sociis Natoque Penatibus Et Magnis Dis Parturient Montes.</p>
                    <p class="paragraph01 text-left wow fadeInUp" data-wow-duration="1s">Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Nullam Blandit Hendrerit Faucibus. Suspendisse Hendrerit Turpis Dui, Eget Ultricies Erat Consequat Ut. Sed Ac Velit Iaculis, Condimentum Neque Eu, Maximus Urna. Maecenas Vitae Nunc Sit Amet Risus Aliquet Facilisis. Cum Sociis Natoque Penatibus Et Magnis Dis Parturient Montes.</p>
                    <p class="paragraph01 text-left wow fadeInUp" data-wow-duration="1s">Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Nullam Blandit Hendrerit Faucibus. Suspendisse Hendrerit Turpis Dui, Eget Ultricies Erat Consequat Ut. Sed Ac Velit Iaculis, Condimentum Neque Eu, Maximus Urna. Maecenas Vitae Nunc Sit Amet Risus Aliquet Facilisis. Cum Sociis Natoque Penatibus Et Magnis Dis Parturient Montes.</p>
                    <p class="paragraph01 text-left wow fadeInUp" data-wow-duration="1s">Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Nullam Blandit Hendrerit Faucibus. Suspendisse Hendrerit Turpis Dui, Eget Ultricies Erat Consequat Ut. Sed Ac Velit Iaculis, Condimentum Neque Eu, Maximus Urna. Maecenas Vitae Nunc Sit Amet Risus Aliquet Facilisis. Cum Sociis Natoque Penatibus Et Magnis Dis Parturient Montes.</p>
                    <p class="paragraph01 text-left wow fadeInUp" data-wow-duration="1s">Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Nullam Blandit Hendrerit Faucibus. Suspendisse Hendrerit Turpis Dui, Eget Ultricies Erat Consequat Ut. Sed Ac Velit Iaculis, Condimentum Neque Eu, Maximus Urna. Maecenas Vitae Nunc Sit Amet Risus Aliquet Facilisis. Cum Sociis Natoque Penatibus Et Magnis Dis Parturient Montes.</p>
                 </div>
              </div>
           </div>
         </div>
      </section>
      {/* <!-- blog section ends --> */}

      <CaseEvalution/>
      <Footer/>
   
   </>
  )
}

export default PracticeDetail