import React, { useState, useEffect } from "react";
import Style from "./WebStyle";
import bannerImage from "../../assets/images/banner_img.png";
import testimonialImage from "../../assets/images/testi_bg.png";
import Header from "./header";
import WOW from "wowjs";
import "animate.css/animate.css";
import CaseEvalution from "./caseEvalution";
import Footer from "./footer";
import News from "./news";
import { useNavigate, useLocation } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  const [isSticky, setIsSticky] = useState(false);
  const wow = new WOW.WOW();

  const [active, setActive] = useState(0);

  useEffect(() => {
    wow.init();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Style />
      <Header />

      {/* <!-- banner section starts --> */}
      <section
        class="hero-section bg_style_one"
        style={{ backgroundImage: `url(${bannerImage})` }}
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="law-heads ">
                <h1 class=" wow fadeInLeft" data-wow-delay="0.1s">
                  Personal <br />
                  Injury Lawyer <br />
                  in Fresno
                </h1>
                <p class="wow fadeInLeft" data-wow-delay="0.2s">
                  Welcome to The Accidents Attorney, Fresno's premier personal
                  injury and accident law firm. We will fight for you.
                </p>
                <a
                  class="btn-7 btn-style-one mt-3 wow fadeInLeft"
                  data-wow-delay="0.3s"
                >
                  <span
                    class="white-color"
                    onClick={() => navigate("/contactus")}
                  >
                    TELL US YOUR CASE
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- banner section ends --> */}

      {/* <!-- about us section section start --> */}
      <section className="Welcome_section">
        <div className="container">
          <div className="row align-items-center">
            <div className="bg_img">
              <img src="CSSweb/images/law_bg.png" />
            </div>
            <div className="col-md-12">
              <div className="law_bx text-center">
                <div
                  className="heading-style-one mb-4 wow fadeInUp"
                  data-wow-delay="0.1s"
                >
                  <h2>Welcome To The Accidents Attorney</h2>
                  <div className="animation_heading_style mb-5"></div>
                  <h3 className="wow fadeInUp" data-wow-delay="0.3s">
                    Fresno Personal Injury Lawyer
                  </h3>
                </div>
                <p className="wow fadeInUp" data-wow-delay="0.2s">
                  At The Accidents Attorney, our priority is your legal
                  security. We have represented individuals in diverse legal
                  areas. We represent each lawsuit individually and invest our
                  very best minds in every case we take on. Your legal success
                  is our number one priority.
                </p>
                <p className="wow fadeInUp" data-wow-delay="0.3s">
                  The The Accidents Attorney is a Fresno Personal Injury law
                  firm committed to the representation of individuals injured by
                  the negligence of others. When faced with injury, our firm
                  successfully reaches monetary compensation through settlements
                  and judgments. With the ever growing trend of distracted
                  driving, accidents are becoming more common. In recent years,
                  the The Accidents Attorney has achieved financial compensation
                  for their client’s through settlement and judgment. If you
                  were injured in a car accident, truck accident, slip and fall,
                  or by any other major incident, you can rely on The Accidents
                  Attorney to seek the compensation that you deserve.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- about us section section end --> */}

      {/* <!-- Practice Area section section Starts --> */}
      <section class="how_can_help">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div
                class="heading-style-one mb-4 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <h2>How can we help you?</h2>
                <div class="animation_heading_style mb-5"></div>
              </div>
            </div>
            <div
              class="col-lg-6 mt-4"
              onClick={() => navigate("/PracticeDetail")}
            >
              <div class="help_bx wow bounceInUp" data-wow-delay="0.2s">
                <div class="img_bx_help">
                  <img src="CSSweb/images/practice-area/img_001.png" />
                </div>
                <div class="hrlp_cnttn">
                  <h4>
                    <a>
                      Personal Injury <i class="fa-solid fa-arrow-right"></i>
                    </a>
                  </h4>
                  <p>
                    We assist our clients in every aspect of their personal
                    injury matters by formulating a strategy.
                  </p>
                </div>
              </div>
            </div>
            <div
              class="col-lg-6 mt-4"
              onClick={() => navigate("/PracticeDetail")}
            >
              <div class="help_bx wow bounceInUp" data-wow-delay="0.3s">
                <div class="img_bx_help">
                  <img src="CSSweb/images/practice-area/img_002.png" />
                </div>
                <div class="hrlp_cnttn">
                  <h4>
                    <a href="#">
                      Immigration <i class="fa-solid fa-arrow-right"></i>
                    </a>
                  </h4>
                  <p>
                    We provide legal services for all areas of immigration law.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 mt-4">
          <div className="help_bx wow bounceInUp" data-wow-delay="0.2s">
            <div className="img_bx_help">
              <img src="CSSweb/images/practice-area/img_001.png" />
            </div>
            <div className="hrlp_cnttn">
              <h4>
                <a href="#">
                  Personal Injury <i className="fa-solid fa-arrow-right"></i>
                </a>
              </h4>
              <p>
                We assist our clients in every aspect of their personal injury
                matters by formulating a strategy.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 mt-4">
          <div className="help_bx wow bounceInUp" data-wow-delay="0.3s">
            <div className="img_bx_help">
              <img src="CSSweb/images/practice-area/img_002.png" />
            </div>
            <div className="hrlp_cnttn">
              <h4>
                <a href="#">
                  Immigration <i className="fa-solid fa-arrow-right"></i>
                </a>
              </h4>
              <p>We provide legal services for all areas of immigration law.</p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Practice Area section section end --> */}

      {/* <!-- Help section section Starts --> */}
      <section className=" sec-help-us">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 col-lg-6">
              <div className="img-wrap wow bounceInLeft" data-wow-delay="0.2s">
                <img
                  src="CSSweb/images/about_me.jpg"
                  alt="Image"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-md-6 col-lg-5">
              <div
                className="heading-style-two mb-4 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <h2>The Accidents Attorney Can Help</h2>
                <div className="animation_heading_style mb-5"></div>
                <p>
                  The The Accidents Attorney has been helping victims of
                  personal injury, car accidents, motorcycle accidents, truck
                  accidents, and other injury accidents in California.
                </p>
              </div>
              <div className="box wow bounceInRight" data-wow-delay="0.3s">
                <div className="custom-media d-flex">
                  <span className="number">01</span>
                  <div>
                    <h2>Car Accidents</h2>
                    <p>
                      Our car accident attorneys will make you a priority right
                      away.
                    </p>
                  </div>
                </div>
                <div className="custom-media d-flex">
                  <span className="number">02</span>
                  <div>
                    <h2>Motorcycle Accidents</h2>
                    <p>
                      Motorcycle accident injuries can severely damage the brain
                      or cause other bodily injuries.
                    </p>
                  </div>
                </div>
                <div className="custom-media d-flex mb-0">
                  <span className="number">03</span>
                  <div>
                    <h2>Truck Accidents</h2>
                    <p>
                      Agile representation for truck accidents against the
                      Insurance companies.
                    </p>
                  </div>
                </div>
                <div className="custom-media d-flex mb-0">
                  <span className="number">04</span>
                  <div>
                    <h2>Slip & Fall</h2>
                    <p>
                      Over a million people slip and fall each year. Don't end
                      up in the hospital.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Help section section end --> */}

      {/* <!-- About section section Starts --> */}
      <section className="abt_section">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-7">
              <div className="abit_text-xtn">
                <div
                  className="heading-style-two mb-4 wow fadeInUp"
                  data-wow-delay="0.1s"
                >
                  <h2>About Just Law</h2>
                  <div className="animation_heading_style mb-5"></div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut.
                  </p>
                </div>
                <div className="row">
                  <div className="col-md-4 mb-4">
                    <div
                      className="countersct wow fadeInUp"
                      data-wow-delay="0.2s"
                    >
                      <i className="fa-regular fa-scale-balanced"></i>
                      <h4>Over 15 years</h4>
                      <p>Experience helping clients in Fresno & California.</p>
                    </div>
                  </div>
                  <div className="col-md-4 mb-4">
                    <div
                      className="countersct wow fadeInUp"
                      data-wow-delay="0.3s"
                    >
                      <i className="fa-solid fa-dollar-sign"></i>
                      <h4>$9 million</h4>
                      <p>Recovered from accidents and injury cases.</p>
                    </div>
                  </div>
                  <div className="col-md-4 mb-4">
                    <div
                      className="countersct wow fadeInUp"
                      data-wow-delay="0.4s"
                    >
                      <i className="fa-solid fa-gavel"></i>
                      <h4>Over 500</h4>
                      <p>International affiliates and partnerships</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 pr-0">
              <div className="abt_img wow flipInX" data-wow-delay="0.2s">
                <img
                  src="CSSweb/images/abt_img.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- About section section end --> */}

      {/* <!-- News section section Starts --> */}
      <section className="news_sections">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="heading-style-one mb-4 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <h2>News</h2>
                <div className="animation_heading_style mb-5"></div>
              </div>
            </div>
            <News />
          </div>
        </div>
      </section>
      {/* <!-- News section section end --> */}

      {/* <!-- Personal Injury section Starts --> */}
      <section className="personal_injry_section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div
                className="heading-style-one mb-4 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <h2>Personal Injury Lawyer Fresno – Award Winning Lawyers</h2>
                <div className="animation_heading_style mb-5"></div>
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-2">
              <div
                className="logo_bg_injry wow bounceInUp"
                data-wow-delay="0.1s"
              >
                <img src="CSSweb/images/logo/logo_1.png" />
              </div>
            </div>
            <div className="col-md-2">
              <div
                className="logo_bg_injry wow bounceInUp"
                data-wow-delay="0.2s"
              >
                <img src="CSSweb/images/logo/logo_2.png" />
              </div>
            </div>
            <div className="col-md-2">
              <div
                className="logo_bg_injry wow bounceInUp"
                data-wow-delay="0.3s"
              >
                <img src="CSSweb/images/logo/logo_3.png" />
              </div>
            </div>
            <div className="col-md-2">
              <div
                className="logo_bg_injry wow bounceInUp"
                data-wow-delay="0.4s"
              >
                <img src="CSSweb/images/logo/logo_4.png" />
              </div>
            </div>
            <div className="col-md-2">
              <div
                className="logo_bg_injry wow bounceInUp"
                data-wow-delay="0.5s"
              >
                <img src="CSSweb/images/logo/logo_5.png" />
              </div>
            </div>
            <div className="col-md-1"></div>
          </div>
        </div>
      </section>
      {/* <!-- Personal Injury section end --> */}

      {/* <!-- Testimonial section Starts --> */}
      <section
        className="testimonial_section bg_style_one"
        style={{ backgroundImage: `url(${testimonialImage})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-9 mx-auto">
              <div
                className="heading-style-one mb-4 mb-4 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <h2>What Our Clients Says</h2>
                <div className="animation_heading_style mb-5"></div>
              </div>
              <div className="text-center">
                <div className="glide mb-4 wow fadeInUp" data-wow-delay="0.1s">
                  <div className="glide__track" data-glide-el="track">
                    <ul className="glide__slides">
                      {active === 0 && (
                        <li className="glide__slide">
                          <div className="slide">
                            <p>
                              “I truly appreciate the support on my case and it
                              was not left unattended this group has taken great
                              care of my case and never gave up for fighting to
                              win my case and works faster and with all the
                              satisfaction of their client. Congratulations for
                              all the success and may you help more people in
                              need of your guidance! Awesome job from beginning
                              to end. I can't thank you much enough."
                            </p>
                            <img src="CSSweb/images/testimonial/testi_1.png" />
                            <h3>Coleen</h3>
                          </div>
                        </li>
                      )}
                      {active === 1 && (
                        <li className="glide__slide">
                          <div className="slide">
                            <p>
                              “I truly appreciate the support on my case and it
                              was not left unattended this group has taken great
                              care of my case and never gave up for fighting to
                              win my case and works faster and with all the
                              satisfaction of their client. Congratulations for
                              all the success and may you help more people in
                              need of your guidance! Awesome job from beginning
                              to end. I can't thank you much enough."
                            </p>
                            <img src="CSSweb/images/testimonial/testi_1.png" />
                            <h3>Coleen</h3>
                          </div>
                        </li>
                      )}
                      {active === 2 && (
                        <li className="glide__slide">
                          <div className="slide">
                            <p>
                              “I truly appreciate the support on my case and it
                              was not left unattended this group has taken great
                              care of my case and never gave up for fighting to
                              win my case and works faster and with all the
                              satisfaction of their client. Congratulations for
                              all the success and may you help more people in
                              need of your guidance! Awesome job from beginning
                              to end. I can't thank you much enough."
                            </p>
                            <img src="CSSweb/images/testimonial/testi_1.png" />
                            <h3>Coleen</h3>
                          </div>
                        </li>
                      )}
                    </ul>
                  </div>
                  {/* <!-- <div className="glide__controls" data-glide-el="controls">
                            <button className="glide__arrow glide__arrow--left" data-glide-dir="|<">prev</button>
                            <button className="glide__arrow glide__arrow--right" data-glide-dir="|>">next</button>
                          </div> --> */}
                  <div className="glide__bullets" data-glide-el="controls[nav]">
                    <button
                      className="glide__bullet"
                      data-glide-dir="=0"
                      onClick={() => setActive(0)}
                    ></button>
                    <button
                      className="glide__bullet"
                      data-glide-dir="=1"
                      onClick={() => setActive(1)}
                    ></button>
                    <button
                      className="glide__bullet"
                      data-glide-dir="=2"
                      onClick={() => setActive(2)}
                    ></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Testimonial section end --> */}

      {/* <!-- Case Evaluation section Start --> */}
      <CaseEvalution />
      {/* <!-- Case Evaluation section end --> */}
      <Footer />
    </>
  );
};

export default Home;
