import React, { useEffect, useState } from "react";
import Sidebar from "./sidebar";
import AdminHeader from "./adminHeader";
import { axiosInstance } from "../axiosInstance";

function CaseEvaluation() {

  const [Data, setData] = useState([]);

  const listCaseList = async () => {
    try {
      const res = await axiosInstance.get("all-cases");
      console.log(res.data.response, "Caselist");
      setData(res.data.response);
    } catch (error) {
      // toast(error.response.data.response.message);
    }
  };
  useEffect(() => {
    listCaseList();
  }, []);


  return (
    <main className="main-page">
      <AdminHeader />

      <div id="wrapper">
        <Sidebar />

        <div className="right-side-content">
          <div id="page-content-wrapper" className="menu-datatable-section caseeva">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-5 web-header">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Case Evaluation List
                      </li>
                    </ol>
                  </nav>
                </div>
                <div className="col-lg-12 table-sectn">
                  <table
                    id=""
                    className="table table-style-two"
                    cellspacing="0"
                    width="100%"
                  >
                    <thead>
                      <tr>
                        <th>S no.</th>
                        <th>Name</th>
                        <th>Phone Number</th>
                        <th>Message</th>
                        <th>Email</th>
                      
                      </tr>
                    </thead>
                    <tbody>
                    {Data &&
                        Data.map((item, i) => {
                          return (
                      <tr>
                        <td>
                          <div className="menu-item-img">
                          {i++ + 1}
                          </div>
                        </td>
                        <td>
                          <div className="menu-item-img">
                          {item.firstName}  {item.lastName} 
                          </div>
                        </td>
                        <td>{item.phoneNumber}</td>
                        <td>
                       {item.case}
                        </td>
                        <td>
                        {item.email}
                        </td>
                      </tr>
                       );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- 1st section end --> */}
        </div>
      </div>
    </main>
  );
}

export default CaseEvaluation;
