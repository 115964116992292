import React, { useEffect, useState } from "react";
import Sidebar from "./sidebar";
import AdminHeader from "./adminHeader";
import { axiosInstance } from "../axiosInstance";

const BlogComment = () => {

  const [Data, setData] = useState([]);

  const listBlogComments = async () => {
    try {
      const res = await axiosInstance.get("admin/all-comments");
      console.log(res.data.response, "Bloglist");
      setData(res.data.response);
    } catch (error) {
      // toast(error.response.data.response.message);
    }
  };

  useEffect(() => {
    listBlogComments();
  }, []);

  return (
    <main className="main-page">
    <AdminHeader />

    <div id="wrapper">
      <Sidebar />

      <div className="right-side-content">
        <div id="page-content-wrapper" className="menu-datatable-section caseeva">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-5 web-header">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li
                      className="breadcrumb-item active"
                      aria-current="page"
                    >
                      Blog Comments List
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="col-lg-12 table-sectn">
                <table
                  id=""
                  className="table table-style-two"
                  cellspacing="0"
                  width="100%"
                >
                  <thead>
                    <tr>
                      <th>S no.</th>
                      <th>Blog Name</th>
                      <th>comment</th>
                      <th>Name</th>
                      <th>Email</th>
                    
                    </tr>
                  </thead>
                  <tbody>
                  {Data &&
                      Data.map((item, i) => {
                        return (
                    <tr>
                      <td>
                        <div className="menu-item-img">
                        {i++ + 1}
                        </div>
                      </td>
                      <td>
                        <div className="menu-item-img">
                        {item.blog_id?.title}
                        </div>
                      </td>
                      <td>
                        <div className="menu-item-img">
                        {item?.comment}
                        </div>
                      </td>
                      <td>
                     {item?.name}
                      </td>
                      <td>
                      {item?.email}
                      </td>
                    </tr>
                     );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- 1st section end --> */}
      </div>
    </div>
  </main>
  )
}

export default BlogComment