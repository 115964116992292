import React, { useEffect } from 'react'
import Style from "./WebStyle";
import WOW from 'wowjs';
import { useNavigate } from "react-router-dom";
import 'animate.css/animate.css';
import { useFormik } from "formik";
import { axiosInstance } from "../axiosInstance"

const CaseEvalution = () => {
    const navigate = useNavigate();
    const wow = new WOW.WOW();
    useEffect(()=>{
        wow.init();
    },[])

    const initialValues = {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        case: "",
      };
      const {
        values,
        errors,
        handleBlur,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      } = useFormik({
        initialValues: initialValues,
        // validationSchema: loginSchema,
        onSubmit: (values) => {
          console.log(values, "valuessss");
          addCase(values);
        },
      });
    
      const addCase = async (values) => {
        const data = {};
        if (values?.firstName) {
          data["firstName"] = values.firstName;
        }
        if (values?.lastName) {
          data["lastName"] = values.lastName;
        }
        if (values?.phoneNumber) {
          data["phoneNumber"] = values.phoneNumber;
        }
        if (values?.email) {
          data["email"] = values.email;
        }
        if (values?.case) {
          data["case"] = values.case;
        }
     
        try {
          const res = await axiosInstance.post("add-case", data);
          resetForm();
          navigate("/")
        } catch (error) {
          alert(error.response.data.error);
        }
      };
  return (
 <>
 <Style/>
 <section class="case_evaluation">
        <div class="container">
            <div class="row">
                 <div class="col-md-6  wow flipInY" data-wow-delay="0.1s">
                     <img src="CSSweb/images/Img_001.png"/>
                 </div>
                 <div class="col-md-6">
                    <div class="heading-style-two mb-4 mb-4 wow fadeInUp" data-wow-delay="0.1s">
                       <h2>Case Evaluation</h2>
                       <div class="animation_heading_style mb-5"></div>
                       <p>Sound legal advice is based on years of training, hard work, and passion, as well as familiarity with legislation and precedent and sound consideration. You’ll find all that and more at The Accidents Attorney.</p>
                    </div>
                    <form class="case_contact wow bounceInRight" data-wow-delay="0.1s" >
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input type="text" name="firstName" placeholder="First Name" class="form-control"  value={values.firstName}
                              onChange={handleChange}
                              onBlur={handleBlur}/>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input type="text" name="lastName" placeholder="Last Name" class="form-control" value={values.lastName}
                              onChange={handleChange}
                              onBlur={handleBlur}/>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input type="text" name="phoneNumber" placeholder="Phone" class="form-control" value={values.phoneNumber}
                              onChange={handleChange}
                              onBlur={handleBlur}/>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input type="text" name="email" placeholder="Email" class="form-control" value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}/>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <textarea type="text" name="case" placeholder="Case Details" class="form-control" value={values.case}
                              onChange={handleChange}
                              onBlur={handleBlur}></textarea>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <button class="btn-7 btn-style-one w-100" onClick={handleSubmit}><span>Submit</span></button>
                                </div>
                            </div>
                        </div>
                    </form>
                        
                    </div>


                 </div>
            </div>
      
    </section>
 </>
  )
}

export default CaseEvalution