import React, { useEffect } from "react";
import Style from "./WebStyle";
import Header from "./header";
import CaseEvalution from './caseEvalution';
import Footer from "./footer";
import WOW from "wowjs";
import "animate.css/animate.css";

const Team = () => {
  const wow = new WOW.WOW();

  useEffect(() => {
    wow.init();
    window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <Style />
      {/* <!-- Header starts --> */}
      <Header />
      {/* <!-- Header ends --> */}

      {/* <!-- banner section starts --> */}
      <section class="banner-section banner-section-team mt-0">
        <div class="overlay"></div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12 d-flex align-items-center justify-content-center">
                    <div class="bakery-heads text-center wow bounceIn" data-wow-delay="0.1s">
                        <h2 class="mb-4 text-uppercase">Our Team</h2>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- banner section ends --> */}


    {/* <!-- team section start --> */}
    <section class="team_section">
        <div class="container">            
            <div class="row">
               <div class="col-md-12">
                   <div class="heading-style-one text-center mb-4 mb-4 wow fadeInUp" data-wow-delay="0.1s">
                     <h2>Our Team</h2>
                     <div class="animation_heading_style mb-5"></div>
                     <p>With more than 60 years of experience serving clients nationwide, <br/>our team is dedicated to help our clients and our community.</p>
                  </div>
               </div>
               <div class="col-xl-4 col-md-6 col-lg-4 mt-4">
                    <div class="single_loyers text-center">
                        <div class="thumb wow bounceIn" data-wow-duration="1s">
                            <img src="CSSweb/images/team/sarwinder.jpg" alt=""/>
                        </div>
                        <h3 class=" wow fadeInUp" data-wow-duration="1s">Sarwinder</h3>
                        <span class=" wow fadeInUp" data-wow-duration="2s">Attorney At Law</span>                       
                    </div>
                </div>
               <div class="col-xl-4 col-md-6 col-lg-4 mt-4">
                    <div class="single_loyers text-center">
                        <div class="thumb wow bounceIn" data-wow-duration="2s">
                            <img src="Cssweb/images/team/victor.jpg" alt=""/>
                        </div>
                        <h3 class=" wow fadeInUp" data-wow-duration="1s">Victor Villalvazo</h3>
                        <span class=" wow fadeInUp" data-wow-duration="2s">Litigation Manager</span>                       
                    </div>
                </div>
               <div class="col-xl-4 col-md-6 col-lg-4 mt-4">
                    <div class="single_loyers text-center">
                        <div class="thumb wow bounceIn" data-wow-duration="3s">
                            <img src="CSSweb/images/team/esmeralda.jpg" alt=""/>
                        </div>
                        <h3 class=" wow fadeInUp" data-wow-duration="1s">Esmeralda Galindo</h3>
                        <span class=" wow fadeInUp" data-wow-duration="2s">Office Manager & Paralegal</span>                       
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- team section end --> */}

    <CaseEvalution/>
    <Footer/>

    </>
  );
};

export default Team;
