import React, { useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-summernote/dist/react-summernote.css";
import { useNavigate } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useFormik } from "formik";
import { axiosInstance } from "../axiosInstance";
// import "./css/custom-style.css";
import "../../css/custom-style.css";
import "../../css/responsive.css";
import "../../css/fonts.css";
import $ from "jquery";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Sidebar from "./sidebar";
import AdminHeader from "./adminHeader";
// import "react-summernote/dist/react-summernote.js";

const AddFaq = () => {
  const navigate = useNavigate();
  const summernoteRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedSubImage, setSelectedSubImage] = useState("");

  const initialValues = {
    question: "",
    answer: "",
  };
  const {
    values,
    errors,
    handleBlur,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    // validationSchema: loginSchema,
    onSubmit: (values) => {
      console.log(values, "valuessss");
      addFaq(values);
    },
  });

  const addFaq = async (values) => {
    const data = {};
    if (values?.question) {
      data["question"] = values.question;
    }
    if (values?.answer) {
      data["answer"] = values.answer;
    }
 
    try {
      const res = await axiosInstance.post("add-faq", data);
      resetForm();
      navigate("/faqlist")
    } catch (error) {
      alert(error.response.data.error);
    }
  };



  return (
    <main className="main-page">
     <AdminHeader/>
      <div id="wrapper">
        <Sidebar/>
        <div className="right-side-content right-side-content-two">
          <div
            id="page-content-wrapper"
            className="dashboard-menuadd-section dashboard-admin-common collap-common-css"
          >
            <div className="container-fluid">
              <div className="web-header">
                <button
                  type="button"
                  className="collape-style"
                  data-toggle="collapse"
                  data-target="#Headertarget"
                >
                  Add FAQ <i className="fa-solid fa-angle-down"></i>
                </button>
              </div>
              <div id="Headertarget" className="row collapse show  mt-4">
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-lg-8 mt-0">
                      <div className="inputfld-style-one">
                        <div className="inputfld-text-btn">
                          <div className="input-textselect w-100">
                            <label>Question</label>
                            <input
                              type="text"
                              name="question"
                              placeholder="Add heading text here"
                              value={values.question}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8 mt-2">
                      <div className="summernote-group">
                        <label>Answer</label>
                        <div ref={summernoteRef}>
                          <CKEditor
                            editor={ClassicEditor}
                            data="<p>Answer</p>"
                            onReady={(editor) => {
                              // You can store the "editor" and use when it is needed.
                              console.log("Editor is ready to use!", editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setFieldValue("answer", data);
                              // console.log(values, "taa");
                            }}
                            onBlur={(event, editor) => {
                              console.log("Blur.", editor);
                            }}
                            onFocus={(event, editor) => {
                              console.log("Focus.", editor);
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-8 mt-2">
                      <div className="inputfld-style-one">
                        <div className="inputfld-text-btn">
                          <div className="input-textselect w-100">
                            <label>Meta Title</label>
                            <input
                              type="text"
                              name="metaTitle"
                              placeholder="Add heading text here"
                              value={values.metaTitle}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8 mt-2">
                      <div className="inputfld-style-one">
                        <div className="inputfld-text-btn">
                          <div className="input-textselect w-100">
                            <label>Meta Description</label>
                            <input
                              type="text"
                              name="metaDescription"
                              placeholder="Add heading text here"
                              value={values.metaDescription}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  
                   
                    <div className="col-lg-8 mt-3 text-right w-100">
                      <button
                        type="btn"
                        className="btn btn-style-two"
                        onClick={handleSubmit}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default AddFaq;
