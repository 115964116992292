import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/Admin/login";
import BlogList from "./components/Admin/blogList";
import Blogs from "./components/Admin/blogs";
import CaseEvaluation from "./components/Admin/caseEvaluation";
import EditBlogs from "./components/Admin/editBlog";
import BlogPage from "./components/Pages/blogPage";
import BlogComment from "./components/Admin/blogComment";
import AddFaq from "./components/Admin/addFaq";
import FaqList from "./components/Admin/faqList";
import BlogDetail from "./components/Pages/blogDetail";
import EditFaq from "./components/Admin/editFaq";
import Dashboard from "./components/Admin/dashboard";
import Home from "./components/Pages/home";
import AboutUs from "./components/Pages/about";
import PracticeArea from "./components/Pages/practiceArea";
import CaseEvalution from "./components/Pages/caseEvalution";
import Team from "./components/Pages/team";
import ContactUs from "./components/Pages/contactUs";
import Faq from "./components/Pages/faq";
import PracticeDetail from "./components/Pages/practiceDetail";
import { Helmet } from "react-helmet";

function App() {
  return (
    <>
      <Helmet>
        <title>The Accidents Attorney</title>
        <meta name="keywords" content="Accidents/law/immigration" />
        <meta name="description" content="get all knowledge" />
      </Helmet>
      <Router>
        <Routes>
          {/* admin pages */}
          <Route path="/administrator" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/bloglist" element={<BlogList />} />
          <Route path="/addblogs" element={<Blogs />} />
          <Route path="/editblog" element={<EditBlogs />} />
          <Route path="/caseevaluationlist" element={<CaseEvaluation />} />
          <Route path="/addfaq" element={<AddFaq />} />
          <Route path="/faqlist" element={<FaqList />} />
          <Route path="/editfaq" element={<EditFaq />} />
          <Route path="/blogcommentlist" element={<BlogComment />} />

          {/* website pages */}
          <Route path="/" element={<Home />} />
          <Route path="/allblogs" element={<BlogPage />} />
          <Route path="/:title" element={<BlogDetail />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/practiceArea" element={<PracticeArea />} />
          <Route path="/CaseEvaluation" element={<CaseEvalution />} />
          <Route path="/OurTeam" element={<Team />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/PracticeDetail" element={<PracticeDetail />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
