import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import WOW from 'wowjs';
import 'animate.css/animate.css';
import { axiosInstance } from "../axiosInstance";

const News = () => {
    const navigate = useNavigate();
    const wow = new WOW.WOW();
    useEffect(()=>{
        wow.init();
    },[])

    const [Data, setData] = useState([]);
  
    const listBlogs = async () => {
      try {
        const res = await axiosInstance.get("admin/all-blogs");
        console.log(res.data.response, "bloglist");
        setData(res.data.response.slice(0, 3))
      } catch (error) {
        // toast(error.response.data.response.message);
      }
    };
    useEffect(() => {
      listBlogs();
    }, []);

  return (
    <>
     {Data &&
                        Data.map((item, i) => {
                          let Url = item.slug;
                          return (
      <div class="col-xl-4 col-lg-4 col-md-6 mt-4">
        <div class="single-practice mb-30 wow bounceInUp" data-wow-delay="0.1s">
          <div class="practice-img">
            <img src="CSSweb/images/blog/blog-img-1.png" alt="" />
            <div class="practice-icon">
              <span>Accidental</span>
            </div>
          </div>
          <div class="practice-caption2" onClick={() => {navigate(`/${Url}`);
                                        }}>
            <h4>{item.title}</h4>
            <p>
            <div dangerouslySetInnerHTML={{
                                  __html: item.description?.length < 35
                                    ? `${item.description}`
                                    : `${item.description?.substring(0, 250)}...`
                                }} /> 
            </p>
            <a  class="read-more1">
              Read more
            </a>
          </div>
        </div>
      </div>
       );
    })}
      
    </>
  );
};

export default News;
