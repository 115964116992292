import React, { useEffect, useState } from "react";
import { axiosInstance } from "../axiosInstance";
import Sidebar from "./sidebar";
import AdminHeader from "./adminHeader";

function Dashboard() {
  const [Blog, setBlog] = useState([]);
  const [CaseList, setCaseList] = useState([]);
  const [FaqList, setFaqList] = useState([]);

  const listBlogs = async () => {

    try {
      const res = await axiosInstance.get("admin/all-blogs");
      console.log(res.data.response, "bloglist");
      setBlog(res.data.response);
    } catch (error) {
      // toast(error.response.data.response.message);
    }
  };
  const listCaseList = async () => {
    try {
      const res = await axiosInstance.get("all-cases");
      console.log(res.data.response, "Caselist");
      setCaseList(res.data.response);
    } catch (error) {
      // toast(error.response.data.response.message);
    }
  };
  const listFaq = async () => {
    try {
      const res = await axiosInstance.get("all-faqs");
      console.log(res.data.response, "bloglist");
      setFaqList(res.data.response);
    } catch (error) {
    }
  };
  useEffect(() => {
    listBlogs();
    listCaseList();
    listFaq();
  }, []);
  return (
    <main className="main-page">
      <AdminHeader />

      <div id="wrapper">
        <Sidebar />

        <div className="right-side-content">
          <div id="page-content-wrapper" className="menu-datatable-section caseeva">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12 web-header">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        DashBoard
                      </li>
                    </ol>
                  </nav>
                </div>
                <div className="col-lg-4">
                    <div className="dash_content text-center">
                        <h2>{Blog?.length}</h2>
                        <h3>Blogs</h3>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="dash_content text-center">
                        <h2>{CaseList.length}</h2>
                        <h3>Case Evaluation</h3>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="dash_content text-center">
                        <h2>{FaqList.length}</h2>
                        <h3>FAQ</h3>
                    </div>
                </div>
               


              </div>
            </div>
          </div>
          {/* <!-- 1st section end --> */}
        </div>
        
      </div>
    </main>
  );
}

export default Dashboard;
