import React, { useEffect, useState } from "react";
import { axiosInstance } from "../axiosInstance";
import deleteIcon from "../../assets/images/icons/delete-icon.png";
import { useNavigate } from "react-router-dom";
import editIcon from "../../assets/images/icons/edit-icon.png";
import config from "../../config";
import Sidebar from "./sidebar";
import AdminHeader from "./adminHeader";


function FaqList() {


  const navigate = useNavigate();
  const [Data, setData] = useState([]);

  const listFaq = async () => {
    try {
      const res = await axiosInstance.get("all-faqs");
      console.log(res.data.response, "bloglist");
      setData(res.data.response);
    } catch (error) {
      // toast(error.response.data.response.message);
    }
  };
  useEffect(() => {
    listFaq();
  }, []);

  const DeleteFaq = async (id) => {
    try {
      const res = await axiosInstance.delete(`delete-faq/${id}`);
      listFaq();
    } catch (error) {}
  };

  return (
    <main className="main-page">
      <AdminHeader />

      <div id="wrapper">
        <Sidebar />

        <div className="right-side-content">
          <div id="page-content-wrapper" className="menu-datatable-section caseeva">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-5 web-header">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        FAQ List
                      </li>
                    </ol>
                  </nav>
                </div>
                <div className="col-lg-12 table-sectn">
                  <table
                    id=""
                    className="table table-style-two"
                    cellspacing="0"
                    width="100%"
                  >
                    <thead>
                      <tr>
                        <th>Question</th>
                        <th>Answer</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                    {Data &&
                        Data.map((item, i) => {
                          return (
                      <tr>
                        <td>{item.question}</td>
                        <td>
                         {item.answer}
                        </td>
                        <td>
                          <div className="toggles">
                            <label className="switch">
                              <input type="checkbox" checked />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </td>
                        <td>
                                <ul className="menu-table-action">
                                  <li onClick={() => DeleteFaq(item._id)}>
                                    <a>
                                      <img
                                        src={deleteIcon}
                                        alt=""
                                        height="18"
                                      />
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      onClick={() =>
                                        navigate("/editfaq", {
                                          state: {
                                            id: item._id,
                                          },
                                        })
                                      }
                                    >
                                      <img src={editIcon} alt="" height="18" />
                                    </a>
                                  </li>
                                </ul>
                              </td>
                      </tr>
                       );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="col-lg-8 mt-3 text-right w-100">
                      <button
                        type="btn"
                        className="btn btn-style-two"
                        onClick={() =>navigate("/addfaq")}
                      >
                       Add FAQ
                      </button>
                    </div>
              </div>
            </div>
          </div>
          {/* <!-- 1st section end --> */}
        </div>
      </div>
    </main>
  );
}

export default FaqList;
