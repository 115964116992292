import React from "react";
import Style from "./WebStyle";
import bgImage from '../../assets/images/bg-map.png';
import { useNavigate } from "react-router-dom";

const Footer = () => {
    const navigate = useNavigate();
  return (
    <>
      <Style />
      <footer class="footer bg_style_two"  style={{ backgroundImage: `url(${bgImage})` }} >
        <div class="container">
            <div class="row">
                <div class="col-lg-5 col-md-5 right">
                    <a href="#"><img src="CSSweb/images/logomain.svg" alt="logo"/></a>
                    <p>We have been providing expert legal advice to clients across the state and assisted many victims in winning fair compensation.</p>
                    <ul class="social_icons">
                        <li><a href="#"><i class="fa-brands fa-facebook-f"></i></a></li>
                        <li><a href="#"><i class="fa-brands fa-linkedin-in"></i></a></li>
                    </ul>
                </div>
                <div class="col-lg-1"></div>
                <div class="col-lg-3 col-md-3 left">
                    <h5>Quick Links</h5>
                    <ul class="quick_links">
                        <li onClick={() => navigate("/practiceArea")}><a ><i class="fa-regular fa-angles-right"></i> Practice Areas</a></li>
                        <li onClick={() => navigate("/aboutus")}><a ><i class="fa-regular fa-angles-right"></i> About Us</a></li>
                        <li onClick={() => navigate("/allblogs")}><a><i class="fa-regular fa-angles-right"></i> News</a></li>
                        <li onClick={() => navigate("/OurTeam")}><a><i class="fa-regular fa-angles-right"></i> Our Team</a></li>
                        <li onClick={() => navigate("/contactus")}><a><i class="fa-regular fa-angles-right"></i> Contact Us</a></li>
                    </ul>
                </div>
                <div class="col-lg-3 col-md-3 left">
                    <h5>Contact us</h5>
                    <ul class="address_ftr_are">
                        <li><a href="#"><i class="fa-solid fa-location-dot"></i> 2170 N. Winery Ave Fresno, CA 93703</a></li>
                        <li><a href="tel:5593422000"><i class="fa-solid fa-phone"></i> (559)342-5000</a></li>
                        <li><a href="mailto:sdhanjan@lawfirmssd.com"><i class="fa-solid fa-envelope"></i> sdhanjan@lawfirmssd.com</a></li>
                    </ul>
                </div>
            </div>

        </div>
        <hr/>
        <div class="copy-rights">
            <p><b>Please note:</b> This website presents general information about The Accidents Attorney. It is not intended as legal advice. Visiting this website does not form an attorney-client relationship. An attorney-client relationship with the office will only be formed upon execution of written agreement. Do not send confidential or sensitive information or documents to us until a written agreement for legal representation has been executed. © 2023 <a href="theaccidentsattorney.com">The Accidents Attorney</a> - A Professional Corporation.</p>
        </div>
    </footer>
    </>
  );
};

export default Footer;
