import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-summernote/dist/react-summernote.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useFormik } from "formik";
import { axiosInstance } from "../axiosInstance";
import "../../css/custom-style.css";
import "../../css/responsive.css";
import "../../css/fonts.css";
import Sidebar from "./sidebar";
import AdminHeader from "./adminHeader";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
// import "react-summernote/dist/react-summernote.js";
import config from "../../config";

const EditFaq = () => {
  const locat = useLocation();
  const navigate = useNavigate();
  const summernoteRef = useRef(null);

  const initialValues = {
    ques: "",
    answer: "",
  };
  const {
    values,
    errors,
    handleBlur,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    // validationSchema: loginSchema,
    onSubmit: (values) => {
      if (locat && locat.state && locat.state.id) {
        updateFaq(values, locat.state.id);
      }
    },
  });
  useEffect(() => {
    if (locat && locat.state && locat.state.id) {
      getFaq(locat.state.id);
    }
  }, [locat.state.id]);

  // ---------------------------------get brand by id -----------------------------------
  const getFaq = async (id) => {
    try {
      const res = await axiosInstance.get(`get-faq/${id}`);
    //   console.log(res.data.response, "title");
      const d = values;
      d["question"] = res.data.response.question;
      d["answer"] = res.data.response.answer;
      setFieldValue(d);
      
    } catch (error) {
      console.log(error);
    }
  };

  // ---------------------------------Update brand -----------------------------------
  const updateFaq = async (values, id) => {
    const data = {};
    if (values?.question) {
      data["question"] = values.question;
    }
    if (values?.answer) {
      data["answer"] = values.answer;
    }

    try {
      const res = await axiosInstance.patch(`edit-faq/${id}`, data);
      //   toast(response.data.data.message);
      navigate("/faqlist")
    } catch (error) {
      //   toast(error.response.data.data.message);
    }
  };


  return (
    <main className="main-page">
      
      <AdminHeader/>

<div id="wrapper">
 <Sidebar/>
        


        <div className="right-side-content right-side-content-two">
          <div
            id="page-content-wrapper"
            className="dashboard-menuadd-section dashboard-admin-common collap-common-css"
          >
            <div className="container-fluid">
              <div className="web-header">
                <button
                  type="button"
                  className="collape-style"
                  data-toggle="collapse"
                  data-target="#Headertarget"
                >
                  Edit FAQ <i className="fa-solid fa-angle-down"></i>
                </button>
              </div>
              <div id="Headertarget" className="row collapse show  mt-4">
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-lg-8 mt-0">
                      <div className="inputfld-style-one">
                        <div className="inputfld-text-btn">
                          <div className="input-textselect w-100">
                            <label>Question</label>
                            <input
                              type="text"
                              name="question"
                              placeholder="Add heading text here"
                              value={values.question}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8 mt-2">
                      <div className="summernote-group">
                        <label>Answer</label>
                        <div ref={summernoteRef}>
                          <CKEditor
                            editor={ClassicEditor}
                            data={values.answer}
                            onReady={(editor) => {
                              // You can store the "editor" and use when it is needed.
                              console.log("Editor is ready to use!", editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setFieldValue("answer", data);
                              // console.log(values, "taa");
                            }}
                            onBlur={(event, editor) => {
                              console.log("Blur.", editor);
                            }}
                            onFocus={(event, editor) => {
                              console.log("Focus.", editor);
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-8 mt-2">
                      <div className="inputfld-style-one">
                        <div className="inputfld-text-btn">
                          <div className="input-textselect w-100">
                            <label>Meta Title</label>
                            <input
                              type="text"
                              name="metaTitle"
                              placeholder="Add heading text here"
                              value={values.metaTitle}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8 mt-2">
                      <div className="inputfld-style-one">
                        <div className="inputfld-text-btn">
                          <div className="input-textselect w-100">
                            <label>Meta Description</label>
                            <input
                              type="text"
                              name="metaDescription"
                              placeholder="Add heading text here"
                              value={values.metaDescription}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    
                   
                    <div className="col-lg-8 mt-3 text-right w-100">
                      <button
                        type="btn"
                        className="btn btn-style-two"
                        onClick={handleSubmit}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default EditFaq;
