// local
// const config = {
//   apiUrl: "http://localhost:8000/api/",
//   imgUrl: "http://localhost:8000/uploads/",
//   socketUrl: "http://localhost:8000",
// };

// production
const config = {
  apiUrl: "http://159.223.198.215:8000/api/",
  imgUrl: "http://159.223.198.215:8000/uploads/",
  socketUrl: "http://159.223.198.215:8000",
};

export default config;
